(function () {
    angular.module('informaApp')
        .service('ExceptionApiService', ExceptionApiService);

    function ExceptionApiService(BaseApiService, ConstantsSvc) {
        return {
            sendError(error) {
                return BaseApiService.post('exceptions', {error});
                // return $http.post(ConstantsSvc.API.URL + 'exceptions', {error});
            }
        };
    }
})();
